<template>
  <div>
    <v-textarea
      v-if="field.type === 'textarea'"
      outlined
      hide-details
      :label="$t('funds.ec.' + field.name)"
      :value="value"
      filled
      dense
      @input="$emit('input', $event)"
    >
    </v-textarea>
    <v-text-field
      v-if="field.type === 'text'"
      outlined
      hide-details
      :label="$t('funds.ec.' + field.name)"
      :value="value"
      filled
      dense
      @input="$emit('input', $event)"
    ></v-text-field>
    <v-text-field
      v-if="field.type === 'number'"
      outlined
      type="number"
      hide-details
      :label="$t('funds.ec.' + field.name)"
      dense
      filled
      :value="value"
      @input="$emit('input', parseInt($event))"
    ></v-text-field>
    <v-text-field
      v-if="field.type === 'percent'"
      type="number"
      hide-details
      :label="$t('funds.ec.' + field.name)"
      dense
      filled
      :value="value"
      outlined
      @input="$emit('input', parseFloat($event))"
    >
      <template
        v-slot:append
      >
        <div class="pt-1">
          %
        </div>
      </template>
    </v-text-field>
    <v-autocomplete
      v-if="field.type === 'bviFundCategory'"
      hide-details
      :label="$t('funds.ec.' + field.name)"
      dense
      filled
      :value="value"
      outlined
      :items="fundCategories"
      :filter="fundCategoryFilter"
      @input="$emit('input', $event)"
    >
      <template v-slot:item="{item}">
        {{ item.text }} ({{ item.value }})
      </template>
    </v-autocomplete>
    <v-select
      v-if="field.type === 'sustainability'"
      hide-details
      :label="$t('funds.ec.' + field.name)"
      dense
      filled
      :value="value"
      outlined
      :items="sustainabilityOptions"
      @input="$emit('input', $event)"
    >
      <template v-slot:item="{item}">
        {{ item.text }} ({{ item.value }})
      </template>
    </v-select>
    <v-checkbox
      v-if="field.type === 'boolean'"
      hide-details
      outlined
      class="mt-0 mb-2"
      :label="$t('funds.ec.' + field.name)"
      :input-value="value"
      @change="$emit('input', !!$event)"
    ></v-checkbox>
  </div>
</template>
<script>
  export default {
    name: 'EquivalenceCheckField',
    props: {
      value: {
        type: [Number, String, Boolean],
        default: null,
      },
      field: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        sustainabilityOptions: [
          {value: 'O', text: 'Non ESG (nicht nachhaltig)'},
          {value: 'B', text: 'Basic (nicht nachhaltig)'},
          {value: 'E', text: 'ESG (nachhaltig)'},
          {value: 'I', text: 'ESG-Impact (nachhaltig)'},
        ],
        fundCategories: [
          {value:'MA_EURW', text: 'Mischfonds ausgewogen, europäische Währung- en/Europa'},
          {value:'MA_I', text: 'Mischfonds aktienbetont, global'},
          {value:'MAR_D', text: 'Mischfonds aktienbetont, Deutschland'},
          {value:'MAR_EUR', text: 'Mischfonds aktienbetont, Anlageschwerpunkt Euro'},
          {value:'MAR_EURW', text: 'Mischfonds aktienbetont, europäische Währungen'},
          {value:'MAR_I', text: 'Mischfonds ausgewogen, global'},
          {value:'MR_D', text: 'Mischfonds rentenbetont, Deutschland'},
          {value:'MR_EUR', text: 'Mischfonds rentenbetont, Euro'},
          {value:'MR_EURW', text: 'Mischfonds rentenbetont, europäische Währung- en/Europa'},
          {value:'MR_I', text: 'Mischfonds rentenbetont, global'},
          {value:'PEN', text: 'Energiefonds'},
          {value:'PFA', text: 'Forst-, Wald- und Agrarfonds'},
          {value:'PFL', text: 'Flugzeugfonds'},
          {value:'PIF', text: 'Infrastrukturfonds'},
          {value:'PIM', text: 'Immobilienfonds'},
          {value:'PS', text: 'Sonstiger Sachwertefonds'},
          {value:'PSF', text: 'Schiffsfonds'},
          {value:'RC', text: 'Rentenfonds, Corporate Bonds'},
          {value:'RDKK', text: 'Rentenfonds Anlageschwerpunkt spezielle Währungen, Dänische Kronen'},
          {value:'REM', text: 'Rentenfonds Emerging Markets'},
          {value:'REM_K', text: 'Rentenfonds Emerging Markets, Kurzläufer und Geldmarktnahe Instrumente'},
          {value:'REUR_K', text: 'Rentenfonds Euro, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'REUR_L', text: 'Rentenfonds Euro, Langläufer'},
          {value:'REUR_M', text: 'Rentenfonds Euro, Mittelläufer'},
          {value:'REUW', text: 'Rentenfonds europäische Währungen'},
          {value:'REUW_K', text: 'Rentenfonds europäische Währungen, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RGBP', text: 'Rentenfonds Britische Pfund'},
          {value:'RIF', text: 'Rentenfonds fernöstliche Währungen ohne YEN'},
          {value:'RIF_K', text: 'Rentenfonds fernöstliche Währungen mit YEN, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RIFY', text: 'Rentenfonds fernöstliche Währungen ohne YEN, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RIFY_K', text: 'Rentenfonds fernöstliche Währungen mit Yen'},
          {value:'RIG_K', text: 'Rentenfonds global, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RIG_L', text: 'Rentenfonds global, Langläufer'},
          {value:'RIG_M', text: 'Rentenfonds global, Mittelläufer'},
          {value:'RIW', text: 'Rentenfonds sonstige Währungen'},
          {value:'RIW_K', text: 'Rentenfonds sonstige Währungen, Kurzläufer und Geldmarkt nahe Instrumente(RIW-K)'},
          {value:'RUSD', text: 'Rentenfonds US-Dollar'},
          {value:'RUSD_K', text: 'Rentenfonds US-Dollar, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RV', text: 'Rentenfonds variabler Anlageschwerpunkt'},
          {value:'RV_K', text: 'Rentenfonds variabler Anlageschwerpunkt, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'RYEN', text: 'Rentenfonds Yen'},
          {value:'RYEN_K', text: 'Rentenfonds YEN, Kurzläufer und Geldmarkt nahe Instrumente'},
          {value:'S', text: 'Sonstige Fonds m'},
          {value:'WGAR', text: 'Garantiefonds'},
          {value:'WSI', text: 'Wertsicherungsfonds'},
          {value:'XIF', text: 'Infrastrukturfonds'},
          {value:'XMB', text: 'Mitarbeiterbeteiligungsfonds'},
          {value:'XPE', text: 'Private Equity-Fonds'},
          {value:'XROH', text: 'Rohstoffmarkt nahe Investmentfonds'},
          {value:'XS', text: 'Sonstige alternative Investmentfonds'},
          {value:'XV', text: 'Versicherungsfonds'},
          {value:'YGW', text: 'Genussschein- und Wandelanleihenfonds'},
          {value:'Z', text: 'Zielvorgabefonds'},
        ],
      }
    },
    methods: {
      fundCategoryFilter(item, queryText){
        return item.value.toLowerCase().includes(queryText.toLowerCase())
          || item.text.toLowerCase().includes(queryText.toLowerCase())
      },
    },
  }
</script>
