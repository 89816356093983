<template>
  <v-container
    v-if="value"
    fluid
  >
    <v-row class="mt-1">
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col
            v-for="field in fieldConfig.filter(el=>!el.children)"
            :key="field.name"
            cols="12"
            :md="field.cols || 6"
          >
            <EquivalenceCheckField
              v-if="!field.children"
              v-model="value.manual[field.name]"
              :field="field"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col
            v-for="field in fieldConfig.filter(el=>el.children)"
            :key="field.name"
            cols="12"
          >
            <v-card outlined>
              <v-card-text>
                <strong>{{ $t('funds.ec.' + field.name) }}</strong>
                <v-divider class="mt-3 mb-3"></v-divider>
                <EquivalenceCheckField
                  v-for="child in field.children"
                  :key="child.name"
                  v-model="value.manual[field.name][child.name]"
                  :field="child"
                ></EquivalenceCheckField>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-btn
          right
          color="primary"
          @click="$emit('saveEc')"
        >
          {{ $t('actions.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import { mapActions } from "vuex"
  import { equivalenceCheck } from "@/config/dummy"
  import EquivalenceCheckField
    from "@/components/equivalence-check/EquivalenceCheckField"

  export default {
    name: 'EquivalenceCheck',
    components: {EquivalenceCheckField},
    props: {
      productDq: {
        type: String,
        default: null,
      },
      value: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        fields: equivalenceCheck.fields,
        fieldConfig: [
          {
            name: 'bviFundCategory',
            type: 'bviFundCategory',
            cols: 12,
          },
          {
            name: 'sustainability',
            type: 'sustainability',
            cols: 12,
          },
          {
            name: 'recommendedHoldingPeriod',
            type: 'number',
          },
          {
            name: 'stockNeutralPercentage',
            type: 'percent',
          },
          {
            name: 'sustainabilityInfo',
            type: 'textarea',
            cols: 12,
          },
          {
            name: 'sustainabilityRiskInfo',
            type: 'textarea',
            cols: 12,
          },
          {
            name: 'riskBasedManagement',
            type: 'boolean',
          },
          {
            name: 'currencyHedge',
            type: 'boolean',
          },
          {
            name: 'objective',
            children: [
              {
                name: 'capitalProtection',
                type: 'boolean',
              },
              {
                name: 'targetYield',
                type: 'boolean',
              },
            ],
          },
          {
            name: 'strategy',
            children:[
              {
                name: 'fundamental',
                type: 'boolean',
              },
              {
                name: 'quantitative',
                type: 'boolean',
              },
              {
                name: 'thematic',
                type: 'boolean',
              },
            ],
          },
        ],
      }
    },
    computed: {
      tableHeaders() {
        return [
          {
            text: 'ISIN',
            value: 'ids.isin',
          },
          {
            text: 'WKN',
            value: 'ids.wkn',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'KVG',
            value: 'kvg',
          },
          {text: '', value: 'json', sortable: false, class: 'button-column'},
          {text: '', value: 'data-table-expand', sortable: false, class: 'button-column'},
        ]
      },
    },
    methods: {
      ...mapActions(['getEquivalenceCheckData']),
    },
  }
</script>

<style lang="scss">
.funds {
  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: inset 0 0 8px -5px rgb(50 50 50 / 75%), inset 0 0 8px -5px rgb(50 50 50 / 75%);
  }
}
</style>
